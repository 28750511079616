<template>
  <v-container class="col-md-10 px-lg-5 bd-content py-12" role="main">
    <h1 class="bd-title" id="content">Condicions Generals</h1>
    <p class="mt-6">
      L'adquisició de qualssevol dels serveis comporta l'acceptació plena i sense reserves de totes i cadascuna de les
      Condicions Generals que s'indiquen, sense perjudici de l'acceptació de les Condicions Particulars que poguessin
      ser aplicable en adquirir determinats serveis.
      Aquestes Condicions Generals podran ser modificades sense notificació prèvia, per tant, és recomanable llegir
      atentament el seu contingut abans de procedir a l'adquisició de qualsevol dels serveis oferts.
    </p>

    <p>La font dels exàmens són continguts editats provinents de la Generalitat de Catalunya. Departament d'Economia i Coneixement. Consell Interuniversitari de Catalunya (CIC)</p>
    <br>

    <p><strong>1. IDENTIFICACIÓ</strong></p>
    <p>Examenselectivitat, en compliment de la Llei 34/2002, d'11 de juliol, de Serveis de la Societat de la Informació
      i de Comerç Electrònic, l'informa que:</p>
    <blockquote>
      <p>
        La seva denominació social és: Paꜱqual Maꜱ.<br>
        El seu nom comercial és: Examenselectivitat.<br>
        El seu CIF és: 47998845F.<br>
        El seu domicili social està en: Pg. Gracia, 118, 08008 - Barcelona , (Espanya).<br>
      </p>
      <p></p>
    </blockquote>


    <p><strong>2. COMUNICACIONS</strong></p>
    <p>Per a comunicar-se amb nosaltres, posem a la seva disposició diferents mitjans de contacte que detallem a
      continuació:</p>
    <p>
      Email: contacte@examenselectivitat.cat<br>
      Adreça postal: Pg. Gracia, 118, 08008 - Barcelona, (Espanya).<br>
      Totes les notificacions i comunicacions entre els usuaris i Examenselectivitat es consideraran eficaços, amb
      caràcter general, quan es realitzin a través de qualsevol mitjà dels detallats anteriorment.
    </p>
    <p></p>

    <p><strong>3. SERVEIS</strong></p>
    <p>Els serveis oferts per Examenselectivitat consisteixen en la realització d'impressió de documents o la venta
      de software com a servei, amb la configuració particular
      i característiques indicades en el procés de compra. En el cas dels dossiers, el client obtindrà com a resultat
      la impressió sobre paper o altres suports, amb el lliurament en domicili a la direcció mencionada en
      el procés de compra.
    </p>
    <p>
      Examenselectivitat es reserva el dret de decidir, a cada moment, els productes i /o serveis que s'ofereixen als
      Clients. D'aquesta manera, Examenselectivitat podrà, en qualsevol moment, afegir nous productes i /o serveis als
      oferts actualment. <br>
    </p>
    <p>Així mateix Examenselectivitat es reserva el dret a retirar o deixar d'oferir, en qualsevol moment, i sense previ
      avís, qualssevol dels serveis oferts, sense que això afecti els serveis contractats la prestació dels quals no
      hagi finalitzat.<br>
    </p>
    <p>Tot això sense perjudici que l'adquisició d'algun dels serveis només podrà fer-se mitjançant la subscripció o
      registre de l'USUARI, el nom d'Usuari del qual serà el seu correu electrònic que portarà associada una
      contrasenya, els quals li identificaran i habilitaran personalment per a poder tenir accés als serveis.<br>
    </p>
    <p>
      Una vegada dins d'https://examenselectivitat.cat, i per a accedir a la contractació dels diferents serveis,
      l'Usuari
      haurà de seguir totes les instruccions indicades en el procés de compra, la qual cosa suposarà la lectura i
      acceptació de totes les condicions generals i particulars fixades en https://examenselectivitat.cat <br>
    </p>
    <p></p>


    <p><strong>4. DISPONIBILITAT I SUBSTITUCIONS</strong></p>
    <p>
    </p>
    <p>Per a la prestació de serveis, Examenselectivitat, donarà accés de la següent forma: creant el client un compte i
      realitzant l'encàrrec a través de la web seguint els passos indicats en el procés de compra. El Client rebrà
      correus sobre l'enviament i se l'informarà totes les dades necessàries per saber l'estat en el que es troba
      la comanda.<br>
    </p>
    <p>
      En cas que, per qualsevol motiu, Examenselectivitat no pugui realitzar la impressió i, per tant, no pugui
      lliurar-se l'encàrrec al client, se l'informarà al més aviat possible i es realitzarà la devolució de l'import
      pagat per aquest producte concret. <br>
      Examenselectivitat no es responsabilitza dels danys o perjudicis que puguin produir-se a aquest USUARI
      conseqüència de fallades o desconnexions en les xarxes de telecomunicacions i que ocasionin suspensió,
      cancel·lació o interrupció del servei d'https://examenselectivitat.cat durant la prestació d'aquest.<br>
    </p>

    <p></p>


    <p><strong>5. PROCÉS DE COMPRA I/O CONTRACTACIÓ</strong></p>
    <p>
      La recepció de les comandes i la compra en línia, pot realitzar-se en qualsevol moment, <strong>les 24h. del
      dia els 365 dies de l'any.</strong> <br>
      Per a realitzar qualsevol compra a https://examenselectivitat.cat, és necessari que el client sigui major d'edat.
      <br>
      A més caldrà que el client es registri a https://examenselectivitat.cat a través d'un formulari de recollida de
      dades en el qual es proporcioni a Examenselectivitat la informació necessària per a la contractació; dades que en
      qualsevol cas seran veraços, exactes i complets sobre la seva identitat i que el client haurà de consentir
      expressament mitjançant l'acceptació de la política de privacitat de Examenselectivitat.
    </p>
    <p>
      <br>
      Els passos a seguir per a realitzar la compra són:
    </p>
    <ul>
      <li>Afegeixi a la cistella tants productes com desitgi.</li>
      <li>Vagi a la cistella i ompli la informació necessària per a calcular l'enviament</li>
      <li> Indiqui la forma en què desitja rebre la comanda i realitzi el pagament.</li>
      <ul>
        <li>Al dur a terme la comanda, se li enviarà des de hola@examenselectivitat.cat un correu amb tota la
          informació de la comanda que serveix com a comprovant.
        </li>
      </ul>

    </ul>
    <p>
      <span class="title">FACTURACIÓ</span><br>
      Si desitja rebre factura, sol·liciti-ho enviant un correu electrònic a contacte@examenselectivitat.cat
    </p>
    <p>
      <br>
    </p>
    <p><strong><a name="Entregas" id="Entregas"></a>6. LLIURAMENT</strong></p>
    <p>Les zones geogràfiques de lliurament són: Espanya Peninsular, Balears, Portugal Peninsular i
      enviaments a Europa </p>
    <p></p>
    <p><strong>FORMES I TERMINIS DE LLIURAMENT</strong></p>
    <p>La forma de lliurament es tria pel client en el procés de compra. Els terminis de lliurament variaran segons la
      modalitat triada i destí.</p>
    <p>Totes les comandes formalitzades i pagats, que es rebin i completin fins
      a les 12.00 hores d'un dia laborable ( Dilluns a Divendres),</p>
    <p>es procuraran imprimir i lliurar a l'agència de transport en el
      mateix dia, tenint preferència aquelles comandes contractades com a Serveis de Lliurament EXPRESS</p>
    <ul>
      <li><strong>TEMPORADA ALTA</strong> : En èpoques de màxima demanda (inici del curs escolar "setembre-octubre”,
        nadal, exàmens, canvi de quadrimestre, "febrer", o convocatòries d'oposicions, el temps de fabricació i
        enviament es pot veure incrementat en 24h. més del tipus d'enviament triat, exceptuant els tipus de
        Enviament Express, que es fa el possible per a complir els terminis.
      </li>
    </ul>

    <li>
      Enviaments i lliurament de comandes “A domicili”.
      <ul>
        <li><p><strong> Els enviaments amb lliuraments en domicili, es realitzen de Dilluns a Divendres, en dies
          laborables. </strong></p></li>
        <li><p><strong>FESTIVITATS</strong>: Els dissabtes, diumenges i festius, no es fan lliuraments. Festivitats
          Locals, Regionals o Nacionals, tant d'Origen com de Destinació, tampoc es fan recollides, ni lliuraments per
          part del transport, per la qual cosa no es comptabilitzen com a dia laborable.
        </p></li>
        <li> Servei de lliurament a domicili Espanya Peninsular - Servei realitzat per empreses de transport
          independent. <em><a href="#AGENCIAS"> AGÈNCIES</a></em></li>
        <li> Servei de lliurament a domicili Balears - Servei realitzat per empreses de transport independent, tingui en
          compte les indicacions en l'apartat <em><a href="#AGENCIAS"> AGÈNCIES</a></em></li>

        <li>Per a lliuraments en poblacions petites, distants de l'agència de transport, està podria retardar-se *24h.,
          en casos excepcionals.
        </li>
      </ul>
    </li>


    <p>
      <br>
      <em><strong>OBSERVACIONS SOBRE EL LLIURAMENT:</strong></em><strong></strong>
    </p>
    <ul>
      <li>
        <strong><a name="AGENCIAS" id="AGENCIAS"></a>AGÈNCIES: </strong><br>
        L'enviament dels nostres productes es realitza per agències de transport independent l'efectivitat de lliurament
        del qual als nostres clients en el temps acordat és superior al 93%.. ~<br>
        Els enviaments són lliurats en l'adreça del Destinatari facilitada com a &lt; adreça d'enviament &gt; aquesta ha
        de ser completa i clara, amb totes les dades necessàries i exactes pel Remitent, Telèfon nacional, Contacte,
        adreça, núm. portal, escala, pisos, portes, etc.
      </li>
      <li>
        <strong>HORARIS de LLIURAMENTS:</strong><br>
        Els horaris per als lliuraments en dia laborable no podran ser fixats amb anterioritat, ni exigibles per
        l'usuari.
      </li>
      <li>
        <strong> RETARDS EN EL LLIURAMENT</strong><br>
        En cas de retards en el lliurament, el client en cap cas podrà exigir la devolució íntegra o parcial de la
        comanda realitzada o les despeses d'enviament. Així mateix s'informa els clients que en èpoques nadalenques,
        campanyes de venda en línia com Blackfriday , lliurament en horaris no concertats, campanya d'estiu, per
        condicions climatològiques adverses, corts o accessos restringits, tant en la zona de lliurament, com en
        qualsevol de les rutes/enllacis, que el paquet sofreixi o requereixi per a arribar des d'origen a destinació,
        etc. o similar, poden produir-se retards o demores en els lliuraments.
      </li>
      <li>
        <strong>DIRECCIÓ INCOMPLETA, INCORRECTA, ABSÈNCIA REITERADA</strong><br>
        Si per motius d'adreça incompleta, incorrecta, absent en diversos intents de lliurament (2 exactament),
        o refusi de la mercaderia, es generés retorn de mercadaria, o aquesta hagués de ser enviada de nou a aquesta o
        una altra adreça la &lt; recanalització &gt; haurà de ser abonada pel client.<br>
        La impossibilitat de lliurar el producte de manera definitiva per causes imputables al client pels motius
        indicats anteriorment, i provoqui un retorn a les nostres instal·lacions, NO donarà dret al client a reclamar la
        devolució de l'import abonat pel treball encarregat, tret que demostri que la falta de lliurament s'ha degut per
        causes alienes a la seva voluntat, situació extraordinària o força major. El client té un termini de 30 dies des
        de la devolució de la comanda, per a abonar un nou enviament i s'enviaria de nou, o passat
        aquest temps, la comanda passarà a destrucció i NO podrà reclamar cap import.
      </li>
    </ul>
    <p></p>


    <strong>7. PREUS, DESPESES I IMPOSTOS</strong>
    <p>Els preus dels serveis que es recullen en https://examenselectivitat.cat vindran expressats en Euros tret que
      s'expressi el contrari i estaran garantits durant el temps de validesa dels mateixos i sigui com sigui el destí de
      la comanda.</p>
    <p>Els preus finals de tots els serveis, al costat de les seves característiques, les ofertes, descomptes, etc. es
      reflecteixen en cadascun dels serveis que s'ofereixen en https://examenselectivitat.cat.</p>
    <p>
      Els serveis tenen l'IVA inclòs.<br>
      El tipus d'IVA és del 21%.<br>
      Despeses d'enviament: estan informats en el lloc web, segons la modalitat de lliurament triat i la quantitat de
      productes seleccionats.
      Enviaments gratuïts per a la Peninsula per a compres superiors de 4 dossiers.
    </p>


    <p></p>

    <p><strong>8. FORMES DE PAGAMENT</strong></p>

    <blockquote>
      <p>
        <strong>· Targeta de crèdit o dèbit · <br></strong>
        Pot realitzar el pagament de manera immediata a través de la passarel·la de pagament segur alimentada per
        la companyia Stripe.
        Si fos necessari realitzar qualsevol devolució o cancel·lació de
        la comanda, l'import es reemborsarà a través de la mateixa targeta de pagament (tardança 5-10 dies).
      </p>

    </blockquote>
    <p></p>

    <p><strong>9. POLÍTICA DE DEVOLUCIONS I DESISTIMENT</strong></p>
    <p>Segons la legislació espanyola i europea (<i>Llei General per a la Defensa dels Consumidors i Usuaris i altres lleis complementàries, RD 1/2007</i>), hi ha algunes pautes generals:</p>

    <ul>
      <li><strong>Dret de desistiment:</strong> Si el consumidor exerceix el dret de desistiment (és a dir, retorna el producte simplement perquè ha canviat d'opinió), les despeses de devolució són a càrrec del consumidor.</li>
      <li><strong>Productes defectuosos o incorrectes:</strong> Si la devolució es deu a un defecte del producte o a un error en l'enviament. Es tornarà a enviar de nou i totes les despeses es faràn càrrec d'Examenselectivitat.</li>
    </ul>


    <p></p>
    <strong> Informació general:</strong>
    <br>
    <p>
      El termini per a desistir del contracte de prestació de serveis és de 14 dies naturals des del dia de la
      celebració del contracte, devent l'usuari posar en coneixement de Examenselectivitat el seu desig d'exercitar tal
      dret, la qual cosa podrà fer emplenant el formulari que es troba al final d'aquest document.<br>
      L'exercici del dret de desistiment dona lloc a la suspensió del servei.<br>
      Examenselectivitat comunicarà al consumidor en suport durador un justificant de recepció d'aquest desistiment.<br>
      El desistiment implica que, Examenselectivitat procedirà a la devolució de l'import ja abonat pel client en un
      termini màxim de 14 dies naturals, seguint el mateix procediment triat pel client per al seu abonament (sempre que
      el servei contractat no estigui dins de les excepcions indicades anteriorment).<br>
      Una vegada rebem la seva sol·licitud ens posarem en contacte amb el client per a indicar-li els detalls de la
      devolució.
    </p>
    <p></p>
    <p><strong><a name="Anular" id="Anular"></a>ANULACIÓ DE COMANDES i COMANDES EN PROCÉS:</strong></p>


    <p> Pot anul·lar la comanda mentre la recepció de la comanda i la comunicació d'anul·lació no hagin transcorregut
      més de 3 hores<br></p>
    <p>Es pot sol·licitar per correu a contacte@examenselectivitat.cat i el reemborsament serà retornat a la mateixa
      targeta bancària amb la qual s'havia dut a terme el pagament.
    </p>
    <p><strong>No es podrà anul·lar la comanda, si ha rebut un correu expressant que la seva comanda està de camí.</strong></p>
    <br>
    <p> </p>


    <p><a name="RESPONSABILIDAD" id="RESPONSABILIDAD"></a><strong> 10. RESPONSABILITAT I EXONERACIÓ DE
      RESPONSABILITAT</strong></p>
    <p>Així mateix Examenselectivitat compleix amb la normativa vigent de protecció de dades. Disposa d'informació
      ampliada en la nostra <em> <a href="politica-de-privicitat"> Política de Privacitat</a></em></p>
    <p> Examenselectivitat podrà suspendre, retirar o cancel·lar parcial o totalment els serveis sotmesos a contractació
      en qualsevol moment i sense necessitat d'avís previ. La previsió anterior no afectarà aquells serveis que
      estan reservats per a usuaris registrats o que són objecte de contractació prèvia i que es regiran per les seves
      condicions específiques.</p>
    <p> </p>



    <p><strong>11. ATENCIÓ AL CLIENT I RECLAMACIONS</strong><br>
    </p>
    <p>Per a interposar qualsevol queixa o reclamació davant Examenselectivitat el client haurà de dirigir-se al departament
      <a href="contacte">d'atenció al client</a> bé a través de
        l'adreça de correu
      electrònic contacte@examenselectivitat.cat.</p>

    <li> Per a obrir una incidència/reclamació ha d'indicar-nos el número de la comanda/encarrego i el correu
      utilitzat a l'hora de dur a terme la comanda
      per qualsevol dels mitjans indicats
    </li>
    <br>
    <p> </p>
    <strong>Resolució de litigis en línia - R.L.L.</strong>
    <br>
    Segons la Resolució de litigis en línia en matèria de consum de la UE i conforme a l'Art. 14.1 del Reglament (UE)
    524/2013, l'informem que la Comissió Europea facilita a tots els consumidors una plataforma de resolució de
    litigis en línia que es troba disponible en el següent enllaç: <a href="https://ec.europa.eu/consumers/odr"
                                                                      target="_blank" rel="nofollow">
    http://ec.europa.eu/consumers/odr/.</a>
    <p></p>
    <p> </p>

    <p><strong>12. LEGISLACIÓ APLICABLE</strong></p>
    <p>
      Les condicions presents es regiran per la legislació espanyola vigent.<br>
      La llengua utilitzada serà el Català.
    </p>

  </v-container>
</template>

<script>
export default {
  name: "CondicionesGenerales"
}
</script>

<style scoped>

</style>
